import { Button, Col, Dropdown, Row } from "antd";
import React from "react";
import { Logo } from "../../components/index.ts";
import { useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

const Item = ({
  NAV_ITEMS,
  handleNavigate,
  items,
}: {
  NAV_ITEMS: {
    label: string;
    isCount: boolean;
    counts: number;
    url: string;
  }[];
  items: {
    key: string;
    label: React.JSX.Element;
  }[];
  handleNavigate: (item: any, index: number) => void;
}) => {
  const { pathname } = useLocation();

  return (
    <div className="w-full h-[72px] flex justify-center bg-white border-b border-[#EAECF0] font-sans">
      <div className="container h-[72px] bg-[#FFFFFF] border-b border-[#EAECF0] fixed z-50 ">
        <Row>
          <Col span={4} className="flex items-center justify-start h-[72px] ">
            <Logo isHeader />
          </Col>

          <Col span={16} className="h-[72px] flex items-center gap-5 pl-5">
            {NAV_ITEMS.map((item: any, index: number) => (
              <Button
                key={index}
                onClick={() => {
                  handleNavigate(item, index);
                }}
                className={`h-[44px] shadow-none border-none  font-sans p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#344054] flex items-center gap-2  ${
                  pathname === item.url && "bg-[#EFF4FF]"
                }`}
              >
                {item?.label}

                {item?.isCount && (
                  <div className="rounded-[16px] bg-[#F2F4F7] px-3 font-sans">
                    {item.counts}
                  </div>
                )}
              </Button>
            ))}
          </Col>

          <Col span={4} className="flex items-center justify-end gap-6">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              className=" shadow-none p-0 "
            >
              <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer flex items-center justify-center">
                <UserOutlined className="text-lg" />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Item;
