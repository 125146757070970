import React from "react";
import { Typography, Button, Radio, Input } from "antd";
import { FormikProps } from "formik";
import { RegisterUser } from "../../../types/user";
import { toast } from "react-toastify";

const BackgroundInfo = ({
  setActiveTab,
  formik,
}: {
  setActiveTab: (newVal: number) => void;
  formik: FormikProps<RegisterUser>;
}): JSX.Element => {
  const { Text } = Typography;

  return (
    <div>
      <div className="grid ">
        <Text className=" text-[#101828] text-[24px] font-semibold">
          My Placement Experience
        </Text>

        <Text className="text-[#475467] text-sm font-normal ">
          The 4 percentages must be whole numbers, between 0 and 100, that add
          up to 100%.
        </Text>
      </div>

      <div className="my-2 mt-4">
        <Text className="text-[#475467] text-sm font-normal ">
          Permanent Direct Hire. Contingent Placement Fee*
        </Text>
        <div className="flex items-center">
          <Input
            name="placementPercentage1"
            type="number"
            onChange={formik.handleChange}
            value={`${formik.values.placementPercentage1}`}
            min={0}
            max={100}
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          />
          <div className="bg-gray-200 px-8 py-3 rounded-[8px] -ml-8 z-50">
            %
          </div>
        </div>
      </div>

      <div className="my-2 mt-4">
        <Text className="text-[#475467] text-sm font-normal ">
          Permanent Direct Hire. Retained Fee (up front, milestone, success
          payment)*
        </Text>
        <div className="flex items-center">
          <Input
            name="placementPercentage2"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.placementPercentage2}
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            min={0}
            max={100}
          />
          <div className="bg-gray-200 px-8 py-3 rounded-[8px] -ml-8 z-50">
            %
          </div>
        </div>
      </div>

      <div className="my-2 mt-4">
        <Text className="text-[#475467] text-sm font-normal ">
          Contingent Worker (Temp/Staffing/Contractor)*
        </Text>
        <div className="flex items-center">
          <Input
            name="contingentWorker"
            onChange={formik.handleChange}
            value={formik.values.contingentWorker}
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            type="number"
            min={0}
            max={100}
          />
          <div className="bg-gray-200 px-8 py-3 rounded-[8px] -ml-8 z-50">
            %
          </div>
        </div>
      </div>

      <div className="my-2 mt-4">
        <Text className="text-[#475467] text-sm font-normal ">
          Temp to Direct Hire (Temp to Perm)*
        </Text>
        <div className="flex items-center">
          <Input
            name="tempToDirectHire"
            onChange={formik.handleChange}
            value={formik.values.tempToDirectHire}
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            type="number"
            min={0}
            max={100}
          />
          <div className="bg-gray-200 px-8 py-3 rounded-[8px] -ml-8 z-50">
            %
          </div>
        </div>
      </div>

      <Text>
        Total:{" "}
        {formik.values?.placementPercentage1 +
          formik.values?.placementPercentage2 +
          Number(formik.values?.contingentWorker) +
          Number(formik.values?.tempToDirectHire)}
      </Text>

      <div className="mt-6 grid">
        <Text className="text-[#344054] text-sm font-medium">
          Do you use an ATS
        </Text>

        <Radio.Group
          onChange={(e) =>
            formik.setValues({
              ...formik.values,
              ATS: e.target.value,
            })
          }
          value={formik.values.ATS}
        >
          <Radio
            className="font-normal text-[#475467] text-[14px] mt-2 rounded-full"
            value={"Yes"}
          >
            Yes
          </Radio>

          <Radio
            className="font-normal text-[#475467] text-[14px] mt-2 rounded-full"
            value={"No"}
          >
            No
          </Radio>
        </Radio.Group>
      </div>

      <Button
        type="primary"
        className="w-full h-[44px] my-4 bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
        onClick={() => {
          if (
            formik?.values?.placementPercentage1 <= 0 ||
            formik?.values?.placementPercentage2 <= 0 ||
            formik?.values?.contingentWorker?.length === 0 ||
            formik?.values?.tempToDirectHire?.length === 0
          ) {
            toast.error("Please complete all required fields.");
          } else setActiveTab(3);
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default BackgroundInfo;
