import { useState } from "react";
import { Job } from "../types/jobs";
import { useDispatch } from "react-redux";
import { jobsApi } from "../services/job.ts";
import { addOpenJobs } from "../redux/open-jobs.ts";

const useOpenJobs = () => {
  const [jobs, setJobs] = useState<Job[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getAllOpenJobs = async () => {
    const jobs = await jobsApi.getAllOpenJobs();

    if (jobs) {
      setJobs(jobs);

      dispatch(addOpenJobs(jobs));
    } else {
      setJobs([]);
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    jobs,
    getAllOpenJobs,
  };
};

export default useOpenJobs;
