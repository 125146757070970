import { useState } from "react";
import { jobsApi } from "../services/job.ts";
import { useDispatch } from "react-redux";
import { addYourJobs } from "../redux/your-jobs.ts";

const useYourJobs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getJobs = async () => {
    try {
      const jobs = await jobsApi.getJobRequests();

      dispatch(addYourJobs(jobs));
    } catch (error) {
      console.log("error", error);
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    getJobs,
  };
};

export default useYourJobs;
