import React, { createContext, useContext, useEffect, useReducer } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { usersApi } from "../services/user.ts";
import { User } from "../types/user.ts";
import { toast } from "react-toastify";

const HANDLERS = {
  INITIALIZE: "INITIALIZE",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
};

const initialState: {
  user: null | User;
  isAuthenticated: boolean;
  isLoading: boolean;
} = {
  isAuthenticated: Cookies.get("x-auth-token") ? true : false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(user
        ? {
            isAuthenticated: true,
            isLoading: false,
            user,
          }
        : {
            isLoading: false,
            isAuthenticated: user ? true : null,
          }),
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      isAuthenticated: user !== null ? true : false,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => ({ ...state, isAuthenticated: false }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  signIn: (email, password) => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const initialize = async () => {
    try {
      const user = await usersApi.getUserByToken();

      if (user?.data) {
        dispatch({
          type: HANDLERS.INITIALIZE,
          payload: user.data,
        });
      }
    } catch (err) {
      Cookies.remove("x-auth-token");
      console.error(err);
      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: null,
      });
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email, password) => {
    try {
      const user = await usersApi.loginUser({ email, password });
      if (user?.token) {
        toast.success("Successfully Log in");
        Cookies.set("x-auth-token", user.token);
        navigate("/home");
        dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signOut = () => {
    Cookies.remove("x-auth-token");
    dispatch({ type: HANDLERS.SIGN_OUT });
  };

  return (
    <AuthContext.Provider value={{ ...state, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);
