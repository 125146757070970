import React, { useEffect } from "react";
import { Heading } from "../../components/index.ts";
import { Typography } from "antd";
import Loader from "../../components/loader.tsx";
import { RootState } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import usePeople from "../../hooks/use-peoples.tsx";

const Main = () => {
  const { Text } = Typography;

  const peopleRequests = useSelector(
    (state: RootState) => state.addPeople.jobs
  );

  const { isLoading, getAllCandidates } = usePeople();

  useEffect(() => {
    getAllCandidates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container pt-10 ">
      <Heading heading="All Your Submitted Candidates:" subHeading="" />

      {isLoading && peopleRequests?.length === 0 ? (
        <Loader />
      ) : (
        <div
          style={{
            boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
          }}
          className="px-6 w-full my-8 rounded-[12px]  grid border border-[#EAECF0] overflow-hidden"
        >
          {peopleRequests && peopleRequests?.length > 0 ? (
            peopleRequests?.map((item, i) => (
              <div key={i} className="px-10 border-b p-4">
                <Text className="text-[30px] text-[#5A59A1] font-semibold">
                  {item?.firstName &&
                    item.firstName.charAt(0).toUpperCase() +
                      item.firstName.slice(1)}{" "}
                  {item?.lastName &&
                    item.lastName.charAt(0).toUpperCase() +
                      item.lastName.slice(1)}
                </Text>

                <div className="grid items-center">
                  <Text className="text-[#475467] text-[18px] mt-2 font-semibold">
                    Title:
                    <span className="font-normal pl-2">{item?.title}</span>
                  </Text>

                  <Text className="text-[#475467] text-[18px] mt-2 font-semibold">
                    Email:
                    <span className="font-normal pl-2">{item?.email}</span>
                  </Text>

                  <Text className="text-[#475467] text-[18px] mt-2 font-semibold">
                    Phone:
                    <span className="font-normal pl-2">{item?.phone}</span>
                  </Text>

                  <Text className="text-[#475467] text-[18px] mt-2 font-semibold">
                    Position Submitted:
                    <span className="font-normal pl-2">
                      {item?.jobId?.title || "---"}
                    </span>
                  </Text>

                  <div className="flex justify-start items-center gap-2 mt-2">
                    <Text className="text-[#475467] text-[18px]  font-semibold">
                      Status:
                    </Text>

                    <div
                      className={`px-2 py-1 text-center rounded-[15px] text-sm  ${
                        item.status === "Hired"
                          ? "bg-[#d8f0e2] w-[100px] py-1"
                          : item?.status === "Rejected"
                          ? "bg-red-300"
                          : "bg-transparent"
                      }`}
                    >
                      <span>{item?.status}</span>
                    </div>
                  </div>

                  <div>
                    {item?.status === "Rejected" && (
                      <Text className="text-[#475467] text-[18px] mt-2 font-semibold">
                        Reason for Rejection:
                        <span className="font-normal pl-2">
                          {item?.reason || "---"}
                        </span>
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full my-40 justify-center flex items-center">
              <img src="./assets/images/no_job.svg" alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Main;
