import React, { useState } from "react";
import { Typography, Button, Spin, Modal } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/auth-context.tsx";
const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
const LoginForm = () => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { signIn } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);
  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className="w-full flex items-center justify-center pt-12">
        <div className="2xl:w-[60%] xxl:w-[50%] lg:w-[80%] w-[90%] h-full">
          <Title
            className="2xl:pt-20 xxl:pt-20 lg:pt-0 md:pt-0  font-sans text-[36px]"
            style={{ fontWeight: "700" }}
          >
            Log in to SorsX
          </Title>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginFormSchema}
            onSubmit={async (values) =>
              await signIn(values.email, values.password)
            }
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="pt-4">
                  <div>
                    <Title
                      level={5}
                      style={{ color: "#344054" }}
                      className="font-sans font-medium text-[14px]"
                    >
                      Email Address
                    </Title>
                    <Field
                      placeholder="Enter your email address"
                      label="Email Address*"
                      name="email"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    />
                    <div className="h-[16px]">
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <div className="text-red-500  text-[12px] h-[5px] ">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <Title
                      level={5}
                      style={{ color: "#344054" }}
                      className="font-sans font-medium text-[14px] "
                    >
                      Password
                    </Title>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    />
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="password"
                        render={(msg) => (
                          <div className="text-red-500 text-[12px] h-[5px]">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full font-sans h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spin /> : "Log in"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="text-center mt-6 ">
            <Text
              style={{ color: "#475467" }}
              className=" text-[14px] font-sans"
            >
              Forgot your password?{" "}
              <span
                onClick={showModal}
                className="text-[#5A59A1] cursor-pointer font-semibold text-[14px] font-sans"
              >
                Recover Now
              </span>
            </Text>
          </div>
          <div className="text-center">
            <Text
              style={{ color: "#475467 mt-4" }}
              className=" text-[14px] font-sans"
            >
              Don't have a SorsX account?{" "}
              <span
                onClick={() => navigate("/sign-up")}
                className="text-[#5A59A1] cursor-pointer font-semibold text-[14px] font-sans"
              >
                Sign Up!
              </span>
            </Text>
          </div>
        </div>
      </div>
      <Modal
        title="Forgot your password"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <div style={{ fontSize: 16 }} className="text-center">
          Please contact the administrator to reset your password.
        </div>
        <div className="flex items-center gap-1 mt-1 -mb-8 pl-8">
          <img className="h-8" src="/assets/mail.svg" alt="mail" />
          <p>admin@sorsx.com</p>
        </div>
      </Modal>
    </GoogleOAuthProvider>
  );
};
export default LoginForm;
