import { useState } from "react";
import { useDispatch } from "react-redux";
import { candidatesApi } from "../services/candidate.ts";
import { addPeople } from "../redux/peoples.ts";

const usePeople = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getAllCandidates = async () => {
    try {
      const candidates = await candidatesApi.getAllCandidates();

      dispatch(addPeople(candidates));
    } catch (error) {
      console.log("error", error);
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    getAllCandidates,
  };
};

export default usePeople;
