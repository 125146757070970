import React, { useMemo } from "react";
import { Typography, Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { Anchor } from "../../../components/index.ts";
import { SIGNUP_ANCHOR } from "../../../constants/data.ts";
import { FormikProps } from "formik";
import ContactInfo from "./contact-info.tsx";
import BackgroundInfo from "./background-info.tsx";
import Prefference from "./prefference.tsx";
import Finish from "./finish.tsx";
import { RegisterUser } from "../../../types/user.ts";
import { toast } from "react-toastify";
import { ICONS_BY_CATEGORY } from "../../../constants/icons-by-category.tsx";
import countryList from "react-select-country-list";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
const { Text, Title } = Typography;
const MyForm = ({
  setActiveTab,
  activeTab,
  formik,
  isLoading,
}: {
  setActiveTab: (newVal: number) => void;
  activeTab: number;
  formik: FormikProps<RegisterUser>;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);
  return (
    <div className="bg-white py-4">
      <>
        <div className="w-full flex items-center justify-center pt-0">
          <div className="2xl:w-[72%] xxl:w-[60%] xl:w-[88%] w-[90%] h-full">
            <Title className="text-center" style={{ fontWeight: "700" }}>
              Sign up
            </Title>
            <Anchor
              data={SIGNUP_ANCHOR}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              isSign
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-center 2xl:pt-[8px] pt-0">
          <div className="2xl:w-[72%] xxl:w-[50%] xl:w-[80%] w-[90%] h-full">
            {activeTab === 0 && (
              <div className="mt-6 pr-5">
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    First Name*
                  </Text>
                  <Input
                    value={formik.values.firstName}
                    name="firstName"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Last Name*
                  </Text>
                  <Input
                    value={formik.values.lastName}
                    name="lastName"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Email*
                  </Text>
                  <Input
                    value={formik.values.email}
                    name="email"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Password*
                  </Text>
                  <Input
                    value={formik.values.password}
                    type="password"
                    name="password"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Primary Phone No*
                  </Text>
                  <PhoneInput
                    value={formik.values.primaryPhoneNumber}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        primaryPhoneNumber: String(e),
                      })
                    }
                    inputStyle={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "0px 8px 8px 0px",
                    }}
                    countrySelectorStyleProps={{
                      buttonStyle: {
                        height: "40px",
                        backgroundColor: "transparent",
                        borderRadius: "8px 0px 0px 8px",
                      },
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Industry*
                  </Text>
                  <Select
                    mode="multiple"
                    onChange={(e) => formik.setFieldValue("industries", e)}
                    value={formik.values.industries}
                    size="small"
                    style={{ width: "100%" }}
                    className="w-full max-h-[44px] h-[44px] bg-white rounded-[5px] border-[1px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054]  placeholder:font-normal"
                    options={ICONS_BY_CATEGORY.map((x) => ({
                      value: x?.category,
                      label: x?.category,
                    }))}
                  />
                </div>
                <Text className="text-[#101828] text-[24px] font-semibold mb-5">
                  Address
                </Text>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Street Address*
                  </Text>
                  <Input
                    value={formik.values.streetAddress}
                    name="streetAddress"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    City*
                  </Text>
                  <Input
                    value={formik.values.city}
                    name="city"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    State*
                  </Text>
                  <Input
                    value={formik.values.state}
                    name="state"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Zip code*
                  </Text>
                  <Input
                    value={formik.values.zipCode}
                    name="zipCode"
                    className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mb-2">
                  <Text
                    style={{ color: "#344054" }}
                    className="font-sans font-nomral text-[16px]"
                  >
                    Country*
                  </Text>
                  <Select
                    onChange={(e) => formik.setFieldValue("country", e)}
                    value={formik.values.country}
                    size="small"
                    style={{ width: "100%", height: "80px" }}
                    className="w-full max-h-[44px] h-[44px] bg-white rounded-[5px] border-[1px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054]  placeholder:font-normal"
                    options={options.map((x) => ({
                      value: x?.label,
                      label: x?.label,
                    }))}
                  />
                </div>
                <Button
                  type="primary"
                  className="w-full h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold mt-4 text-[16px] text-[#fff]"
                  onClick={() => {
                    if (
                      formik.values.firstName?.length > 0 &&
                      formik.values.lastName?.length > 0 &&
                      formik.values.email?.length > 0 &&
                      formik.values.password?.length > 0 &&
                      formik.values.country?.length > 0 &&
                      formik.values.industries?.length > 0 &&
                      formik.values.streetAddress?.length > 0 &&
                      formik.values.city?.length > 0 &&
                      formik.values.state?.length > 0 &&
                      formik.values.zipCode?.length > 0 &&
                      formik.values.primaryPhoneNumber?.length > 0
                    ) {
                      if (
                        !isPossiblePhoneNumber(formik.values.primaryPhoneNumber)
                      )
                        return toast.error("Phone Number Not Valid");
                      setActiveTab(1);
                    } else toast.error("Please complete all required fields.");
                  }}
                >
                  Continue
                </Button>
                <div className="text-center mt-6 ">
                  <Text style={{ color: "#475467" }} className=" text-[14px]">
                    Already have an account?{" "}
                    <span
                      onClick={() => navigate("/")}
                      className="text-[#5A59A1] cursor-pointer font-semibold text-[14px]"
                    >
                      Log in
                    </span>
                  </Text>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
      <div className="w-full flex items-center justify-center ">
        <div className="2xl:w-[72%] xxl:w-[50%] xl:w-[80%] h-full">
          <div className="pr-5 overflow-y-auto">
            {activeTab === 1 && (
              <ContactInfo formik={formik} setActiveTab={setActiveTab} />
            )}
            {activeTab === 2 && (
              <BackgroundInfo formik={formik} setActiveTab={setActiveTab} />
            )}
            {activeTab === 3 && (
              <Prefference formik={formik} setActiveTab={setActiveTab} />
            )}
            {activeTab === 4 && (
              <Finish formik={formik} isLoading={isLoading} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyForm;
