import React, { useEffect, useState } from "react";
import Card from "./card.tsx";
import EditModal from "./modal.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
import { Job } from "../../types/jobs.ts";
import Loader from "../../components/loader.tsx";
import { useSelector } from "react-redux";
import useOpenJobs from "../../hooks/use-open-jobs.tsx";
import { RootState } from "../../redux/store.ts";
import { Spin, Typography } from "antd";

const Main = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuthContext();
  const { Text } = Typography;
  const jobs = useSelector((state: RootState) => state.openJobs.jobs);
  const { isLoading, getAllOpenJobs } = useOpenJobs();
  const [jobData, setJobData] = useState<Job | undefined>();

  useEffect(() => {
    getAllOpenJobs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container pt-10 ">
        {isLoading && jobs?.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="grid">
              <Text className="text-[#101828] text-[30px] font-semibold font-sans">
                Welcome ,{" "}
                {user ? (
                  user?.firstName &&
                  user.firstName.charAt(0).toUpperCase() +
                    user.firstName.slice(1)
                ) : (
                  <Spin />
                )}
              </Text>
            </div>

            <div
              style={{
                boxShadow:
                  "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
              }}
              className="px-6 w-full my-8 rounded-[12px] bg-white  grid border border-[#EAECF0] overflow-hidden"
            >
              {jobs?.length === 0 ? (
                <div className="flex items-center justify-center w-full py-32">
                  <img src="./assets/images/no_message.svg" alt="" />
                </div>
              ) : (
                <>
                  {jobs?.map((item, i) => (
                    <Card
                      key={i}
                      data={item}
                      setIsOpen={setIsOpen}
                      setJobData={setJobData}
                    />
                  ))}
                </>
              )}
            </div>
          </>
        )}

        <EditModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={jobData}
          getAllOpenJobs={getAllOpenJobs}
        />
      </div>
    </>
  );
};

export default Main;
