export const TOTAL_CARDS2 = [{ label: "Total Revenue", counts: "200K" }];
export const RECENT_ACTIVITY = [
  {
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar.png?updatedAt=1709212217258",
    heading: "Demi Wikinson",
    name: "Adam Jones",
    date: "08/09/23",
  },
];
export const ANCHOR = [{ label: "Approvals" }, { label: "Payments" }];
export const SIGNUP_ANCHOR = [
  { label: "Contact Info" },
  { label: "Company & Tax Info" },
  { label: "Background Info" },
  { label: "Set Preferences" },
  { label: "Finish" },
];
export const FILTER_COMPANY = [{ label: "Catalog" }, { label: "Capsule" }];
export const FILTER_NAME = [{ label: "Brandon Workman" }];
export const FILTER_TYPE = [{ label: "Affiliate" }, { label: "Employee" }];
export const FILTER_TYPE2 = [{ label: "Keira" }, { label: "Mira" }];
export const FILTER_STATUS = [
  { label: "Unpaid", bgColor: "#FFFAEB", color: "#B54708" },
];
export const FILTER_COMPANY_STATUS = [
  { label: "Churned", bgColor: "#FFFAEB", color: "#B54708" },
];
export const TEAM_TABS = [{ label: "Glovendor" }, { label: "Affiliates" }];
export const SETTINGS_TABS = [{ label: "Profile" }, { label: "Password" }];
export const SETTINGS_INTEGRATION = [
  {
    label: "Salesforce",
    icon: "https://ik.imagekit.io/8extk8sjo/salesforce-with-type-logo%201.svg?updatedAt=1709881615283",
    description: "Pull in leads, accounts and revenue data.",
    btntext: "Connect",
    isEnable: false,
  },
];
export const PAYOUTS_DATA_COLUMN = [
  { title: "Name", dataIndex: "name", key: "column1" },
  { title: "Type", dataIndex: "Type", key: "column2" },
  { title: "Amount", dataIndex: "Amount", key: "column3" },
  { title: "Company", dataIndex: "Company", key: "column4" },
  { title: "Status", dataIndex: "Status", key: "column5" },
  { title: "Converted Date", dataIndex: "Date", key: "column6" },
  { title: "", dataIndex: "edit", key: "column7" },
];
export const PAYOUT_DATA = [
  {
    id: "1",
    name: "Brandon Workman",
    Type: "Affiliate",
    Amount: "$800",
    Company: "Mira gouse",
    Date: "24-08-10",
    Status: "Unpaid",
    edit: "",
  },
];
export const COMPANIES_DATA_COLUMN = [
  { title: "Company", dataIndex: "name", key: "column1" },
  { title: "Referred By", dataIndex: "Type", key: "column2" },
  { title: "Closed By", dataIndex: "Amount", key: "column3" },
  { title: "Converted On", dataIndex: "Company", key: "column4" },
  { title: "Status", dataIndex: "Status", key: "column5" },
  { title: "Commission Paid", dataIndex: "Date", key: "column6" },
  { title: "", dataIndex: "edit", key: "column7" },
];
export const COMPANIES_DATA = [
  {
    id: "1",
    name: "Spotify",
    Type: "Kierra",
    Amount: "Kierra",
    Company: "Mira gouse",
    Date: "$6000",
    Status: "Unpaid",
    edit: "",
  },
];
export const TEAMS_ADMIN_DATA = [
  {
    id: "1",
    icon: "https://ik.imagekit.io/8extk8sjo/Avatar%20(4).svg?updatedAt=1710409565037",
    name: "Olivia Rhye",
    email: "email@company.com",
    role: "Admin",
  },
];
export const TEAMS_EMPLOYEE_DATA = [
  {
    id: "1",
    icon: "",
    name: "Drink",
    email: "email@company.com",
    Commission: "Flat 20% for Employees ",
  },
];
export const DASHBOARD_BTNS = [
  {
    label: "1 Month",
    className:
      "h-[44px] rounded-l-[6px] font-sans rounded-r-none  shadow-none p-[8px, 12px, 8px, 12px]  font-semibold text-[16px] text-[#1D2939] flex items-center gap-2",
    style: { shadow: "0px 1px 2px 0px #1018280D", border: "1px solid #D0D5DD" },
  },
];
export const OPEN_JOBS = [
  {
    status: "posted",
    date: "AUG 25, 2024",
    title: "Financial Analyst",
    location: "Istanbul Turkey",
    fee: "300,000TL",
    category: "Supply Chain-Finance",
    recruiters: "05",
    candidates: "01",
  },
];
export const NOTIFICATIONS = [
  {
    title: "Jon Doe",
    details: [{ title: "Title", heading: "Financial Analyst" }],
  },
];
export const DETAILS_1 = [
  { title: "Minimum Qualification", heading: "Bacholor" },
];
export const REQUIREMENTS = [
  { title: "A degree in programming, computer science, or a related field." },
];
export const REQUIREMENTS2 = [
  { title: "Discussing project aims with the client and development team." },
];
