import React from "react";
import Details from "../../views/details/main.tsx";
import Header from "../../layout/header/index.tsx";

const Detail = () => {
  return (
    <>
      <Header />

      <Details />
    </>
  );
};

export default Detail;
