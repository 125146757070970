import React from "react";
import { FormikProps } from "formik";
import { Typography, Button, Input } from "antd";
import { RegisterUser } from "../../../types/user";
import { toast } from "react-toastify";

const ContactInfo = ({
  setActiveTab,
  formik,
}: {
  setActiveTab: (newVal: number) => void;
  formik: FormikProps<RegisterUser>;
}): JSX.Element => {
  const { Text } = Typography;
  return (
    <div>
      <Text className="pt-2 text-[#101828] text-[24px] font-semibold">
        Legal Name
      </Text>
      <div className="mb-2 mt-6">
        <div className="my-1">
          <Text
            style={{ color: "#344054" }}
            className="font-sans font-nomral text-[16px]"
          >
            Legal First Name*
          </Text>
          <Input
            value={formik.values.legalFirstName}
            name="legalFirstName"
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            onChange={formik.handleChange}
            style={{ marginBottom: "16px" }}
          />
        </div>

        <div className="my-1">
          <Text
            style={{ color: "#344054" }}
            className="font-sans font-nomral text-[16px]"
          >
            Legal Last Name*
          </Text>
          <Input
            value={formik.values.legalLastName}
            name="legalLastName"
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="mb-2"></div>
      <Text
        style={{ color: "#344054" }}
        className="font-sans font-nomral text-[16px]"
      >
        Legal Company Name*
      </Text>
      <Input
        value={formik.values.legalCompanyName}
        name="legalCompanyName"
        className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
        onChange={formik.handleChange}
      />
      <Text className="text-[#475467] text-sm font-normal mt-4">
        Legal Company Name must match your tax documentation. You will be paid
        using the name entered here. We reserve the right to ask for supporting
        documentation
      </Text>
      <div className="mb-2 mt-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          DBA Company Name
        </Text>
        <Input
          value={formik.values.DBACompanyName}
          name="DBACompanyName"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>
      <Text className="text-[#475467] text-sm font-normal ">
        If filled out, this is the name that will be shared publicly and seen by
        employers. We reserve the right to ask for supporting documentation
      </Text>
      <div className="mt-4 -mb-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          {" "}
          Company Type*
        </Text>
        <Input
          value={formik.values.companyType}
          name="companyType"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>{" "}
      <div className="mb-2 mt-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          Website
        </Text>
        <Input
          value={formik.values.website}
          name="website"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>
      <div className="mt-4 -mb-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          {" "}
          Firm Size
        </Text>
        <Input
          value={formik.values.firmSize}
          name="firmSize"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>{" "}
      <div className="mb-2 mt-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          Vat
        </Text>
        <Input
          value={formik.values.vat}
          name="vat"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>
      <div className="mb-2">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          Recruiting License Number
        </Text>
        <Input
          value={formik.values.recruitingLicenseNumber}
          name="recruitingLicenseNumber"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
        />
      </div>
      <div className="mb-2">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[16px]"
        >
          Recruiting License Expiration Date
        </Text>
        <Input
          value={formik.values.licenseExpirationDate}
          name="licenseExpirationDate"
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          onChange={formik.handleChange}
          type="date"
        />
      </div>
      <Button
        type="primary"
        className="w-full h-[44px] my-4 bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
        onClick={() => {
          if (
            formik.values.legalFirstName?.length > 0 &&
            formik.values.legalLastName?.length > 0 &&
            formik.values.legalCompanyName?.length > 0 &&
            formik.values.companyType?.length > 0
          ) {
            setActiveTab(2);
          } else toast.error("Please complete all required fields.");
        }}
      >
        Continue
      </Button>
    </div>
  );
};
export default ContactInfo;
