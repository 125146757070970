export const modules = {
  toolbar: [
    [
      {
        size: ["small", false, "large", "huge"],
      },
    ], // Font sizes dropdown
    ["bold", "italic", "underline", "strike"],
    [
      {
        list: "ordered",
      },
      {
        list: "bullet",
      },
    ],
    ["link", "image"],
    [
      {
        font: [],
      },
    ],
    [
      {
        color: [],
      },
    ], // Color dropdown
  ],
};

export const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "link",
  "image",
  "font",
  "color",
];
