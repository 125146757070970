import { configureStore } from "@reduxjs/toolkit";
import openJobs from "./open-jobs.ts";
import yourJobs from "./your-jobs.ts";
import addPeople from "./peoples.ts";

export const store = configureStore({
  reducer: {
    openJobs,
    yourJobs,
    addPeople,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself

export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export type AppDispatch = typeof store.dispatch;
