import React from "react";
import { Typography, Button, Input, Spin } from "antd";
import { FormikProps } from "formik";
import { RegisterUser } from "../../../types/user";
import { toast } from "react-toastify";

const Finish = ({
  formik,
  isLoading,
}: {
  formik: FormikProps<RegisterUser>;
  isLoading: boolean;
}): JSX.Element => {
  const { Text } = Typography;

  return (
    <>
      <div className="space-y-4">
        <div className="mt-4 mb-4">
          <Text
            style={{
              color: "#344054",
            }}
          >
            Where did you hear about us?*
          </Text>

          <Input
            value={formik.values.hearAboutUs}
            name="hearAboutUs"
            onChange={formik.handleChange}
            required
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          />
        </div>

        <div className="mt-4 -mb-4">
          <Text
            style={{
              color: "#344054",
            }}
          >
            What are your primary mean of sourcing?*
          </Text>
          <Input
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
            value={formik.values.sourcing}
            name="sourcing"
            onChange={formik.handleChange}
            required
          />
        </div>

        <div className="mt-4 -mb-4">
          <Text
            style={{
              color: "#344054",
            }}
          >
            What online resources do you use when sourcing?*
          </Text>
          <Input
            value={formik.values.onlineResources}
            name="onlineResources"
            onChange={formik.handleChange}
            required
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          />
        </div>

        <div className="mt-4 -mb-4">
          <Text
            style={{
              color: "#344054",
            }}
          >
            What is your role within your organization?*
          </Text>
          <Input
            value={formik.values.roleInPreviousOrganization}
            name="roleInPreviousOrganization"
            onChange={formik.handleChange}
            required
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          />
        </div>

        <div className="mt-4 -mb-4">
          <Text style={{ color: "#344054" }}>
            At what companies have you successfully placed candidate?*
          </Text>
          <Input
            value={formik.values.companies}
            name="companies"
            onChange={formik.handleChange}
            required
            className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          />
        </div>
        <Button
          type="primary"
          disabled={formik.isSubmitting}
          className="w-full h-[44px] my-8 bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
          onClick={() => {
            if (
              formik.errors?.hearAboutUs ||
              formik.errors?.sourcing ||
              formik.errors?.onlineResources ||
              formik.errors?.roleInPreviousOrganization ||
              formik.errors?.companies
            ) {
              toast.error("Please complete all required fields.");
            } else formik.handleSubmit();
          }}
        >
          {formik.isSubmitting ? <Spin /> : "Continue"}
        </Button>
      </div>
    </>
  );
};

export default Finish;
