import React from "react";
import { Modal, Typography, Button } from "antd";
import { Job } from "../../types/jobs.ts";
import "react-quill/dist/quill.snow.css";
import { capitalizeEachWord } from "../../utils/captitalize-each-word.ts";
const JobDetailModal = ({
  isOpen,
  setIsOpen,
  data,
}: {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  data: Job | undefined;
}) => {
  const { Text } = Typography;
  const date = new Date(data?.createdAt || "");
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return (
    <Modal
      centered
      visible={isOpen}
      width={750}
      style={{ boxShadow: "0px 32px 64px -12px #10182824" }}
      footer={null}
      closable={false}
      wrapClassName="custom-modal"
    >
      <div className="flex justify-between items-center py-4  px-6 border-b">
        <Text className="text-[#101828] text-[18px] font-semibold">
          Job Details
        </Text>
        <img
          src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
          alt=""
          className="cursor-pointer"
          onClick={() => setIsOpen(false)}
        />
      </div>
      <div className="">
        <div className="px-6 py-4">
          <div className="flex items-center gap-5">
            <Text className="text-[#5A59A1] font-semibold text-[14px] capitalize">
              {data?.status}
            </Text>
            <Text className="text-[#5A59A1] font-semibold text-[14px]">
              {formattedDate}
            </Text>
          </div>
          <Text className="font-semibold text-[#344054] text-[24px]">
            {data?.title &&
              data?.title.charAt(0).toUpperCase() + data?.title.slice(1)}
          </Text>
          <div className="flex items-center pt-3 gap-2">
            <img
              src="./assets/images/location.svg"
              className="w-[15px]"
              alt=""
            />
            <Text className="text-[#475467] text-[14px] font-normal">
              {data?.location}
            </Text>
          </div>
          <div className="pt-3 grid">
            {data?.showCompanyName && (
              <Text className="text-[#475467] text-[14px] font-semibold">
                Company Name:{" "}
                <span className="font-normal">{data?.company_name}</span>
              </Text>
            )}
            <Text className="text-[#475467] text-[14px] font-semibold">
              Fee: <span className="font-normal">{data?.feeAmount}</span>
            </Text>
            <Text className="text-[#475467] text-[14px] pt-2 font-semibold">
              Industry & Category:{" "}
              <span className="font-normal">Supply Chain-Finance</span>
            </Text>
            <Text className="text-[#475467] text-[14px] font-semibold">
              Salary Range:{" "}
              <span className="font-normal">{data?.salaryRange}</span>
            </Text>
            <Text className="text-[#475467] text-[14px] font-semibold">
              Job Type:{" "}
              <span className="font-normal">
                {capitalizeEachWord(data?.jobType || "")}
              </span>
            </Text>
            <Text className="text-[#475467] text-[14px] font-semibold">
              Work Authorization:{" "}
              <span className="font-normal">{data?.workAuthorization}</span>
            </Text>
            <Text className="text-[#475467] text-[14px] font-semibold">
              Job Id: <span className="font-normal">{data?.positionId}</span>
            </Text>
          </div>
          <div className="flex">
            <div className="grid border-r-2 w-[85px]  border-[#EFEBFF]">
              <Text className="text-[#5A59A1] text-[14px] pt-2 font-semibold">
                Recruiters
              </Text>
              <Text className="text-[#091534] text-[14px]  font-semibold">
                {
                  data?.jobRequests?.filter(
                    (x) => x?.status.toLowerCase() === "approved"
                  )?.length
                }
              </Text>
            </div>
            <div className="grid w-full  pl-3">
              <Text className="text-[#5A59A1] text-[14px] pt-2 font-semibold">
                Candidates Submitted
              </Text>
              <Text className="text-[#091534] text-[14px]  font-semibold">
                {data?.candidates?.length || 0}
              </Text>
            </div>
          </div>
          <div className="pt-4">
            <Text className="text-[#5A59A1] text-[14px]  font-semibold">
              Public Job Details
            </Text>
            <div
              dangerouslySetInnerHTML={{ __html: data?.publicJobDetails || "" }}
              style={{
                maxHeight: "280px",
                overflowY: "scroll",
                margin: "8px 0px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-8 flex gap-3 border-t  items-center justify-end">
        <Button
          onClick={async () => setIsOpen(false)}
          type="primary"
          size="large"
          className="w-1/2 h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
        >
          close
        </Button>
      </div>
    </Modal>
  );
};
export default JobDetailModal;
