import React from "react";

const Logo = ({ isHeader }: any): JSX.Element => {
  return (
    <img
      onClick={() => {
        window.open("https://sorsx.com");
      }}
      src="/assets/images/SorsX Logos/SorsX Logos/Color logo - no background.png"
      alt=""
      className={` cursor-pointer object-contain ${
        isHeader ? "w-[160px] h-[48px]" : "w-[160px] p-4"
      }`}
    />
  );
};

export default Logo;
