import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Signin from "./pages/auth/sign-in.tsx";
import SignUp from "./pages/auth/sign-up.tsx";
import Dashboard from "./pages/dashboard/index.tsx";
import Payouts from "./pages/payouts/index.tsx";
import TeamManagement from "./pages/team-management/index.tsx";
import Settings from "./pages/settings/index.tsx";
import Detail from "./pages/details/index.tsx";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "./components/auth-guard.ts";
import { GuestGuard } from "./components/guest-guard.ts";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store.ts";
import useOpenJobs from "./hooks/use-open-jobs.tsx";
import useYourJobs from "./hooks/use-your-jobs.tsx";
import { useAuthContext } from "./context/auth-context.tsx";
import usePeople from "./hooks/use-peoples.tsx";
import "./App.css";
import "react-international-phone/style.css";

function App() {
  const openJobs = useSelector((state: RootState) => state.openJobs.jobs);
  const yourJobs = useSelector((state: RootState) => state.yourJobs.jobs);
  const addPeople = useSelector((state: RootState) => state.addPeople.jobs);
  const { getAllOpenJobs } = useOpenJobs();
  const { getJobs } = useYourJobs();
  const { getAllCandidates } = usePeople();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      if (openJobs?.length === 0) getAllOpenJobs();
      if (yourJobs?.length === 0) getJobs();
      if (addPeople?.length === 0) getAllCandidates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuestGuard>
            <Signin />
          </GuestGuard>
        }
      />

      {/* Dashboard route */}

      <Route
        path="/home"
        element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        }
      />

      {/* Normal routes */}

      <Route
        path="/open-jobs"
        element={
          <AuthGuard>
            <Payouts />
          </AuthGuard>
        }
      />

      <Route
        path="/your-jobs"
        element={
          <AuthGuard>
            <TeamManagement />
          </AuthGuard>
        }
      />

      <Route
        path="/candidates"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />

      <Route path="/team-management" element={<TeamManagement />} />

      <Route
        path="/companies"
        element={
          <AuthGuard>
            <TeamManagement />
          </AuthGuard>
        }
      />

      <Route
        path="/details/:jobId"
        element={
          <AuthGuard>
            <Detail />
          </AuthGuard>
        }
      />

      {/* .................Auth Routes.............. */}

      <Route
        path="/sign-up"
        element={
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        }
      />
    </Routes>
  );
}

export default App;
