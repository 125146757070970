import { createSlice } from "@reduxjs/toolkit";
import { JobRequest } from "../types/jobs.ts";

export interface CounterState {
  jobs: JobRequest[];
}

const initialState: CounterState = {
  jobs: [],
};

export const counterSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    addYourJobs: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.jobs = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { addYourJobs } = counterSlice.actions;

export default counterSlice.reducer;
