import * as yup from "yup";
import { RegisterUser } from "../types/user";

export const REGISTER_USER_SCHEMA = yup.object().shape({
  Certification: yup.string(),
  city: yup.string(),
  companies: yup.string().required("Companies is required"),
  companyOrPersonalDescription: yup
    .string()
    .required("Company or personal description is required"),
  companyType: yup.string().required("Company type is required"),
  country: yup.string(),
  DBACompanyName: yup.string(),
  educationLevel: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  ext: yup.string(),
  fax: yup.string(),
  faxExt: yup.string(),
  firmSize: yup.string(),
  firstName: yup.string().required("First name is required"),
  hearAboutUs: yup.string().required("How did you hear about us? is required"),
  industries: yup.array().of(yup.string()).required(),
  lastName: yup.string().required("Last name is required"),
  legalCompanyName: yup.string().required("Legal company name is required"),
  legalFirstName: yup.string().required("Legal first name is required"),
  legalLastName: yup.string().required("Legal last name is required"),
  licenseExpirationDate: yup.string(),
  onlineResources: yup.string().required(),
  password: yup.string().required("Password is required"),
  placementPercentage1: yup.number(),
  placementPercentage2: yup.number(),
  prefix: yup.string(),
  primaryPhoneNumber: yup.string().required("Primary phone number is required"),
  recruitingLicenseNumber: yup.string(),
  schoolsAttended: yup.string(),
  secondaryExt: yup.string(),
  secondaryPhoneNumber: yup.string(),
  sourcing: yup.string().required(),
  state: yup.string(),
  streetAddress: yup.string(),
  vat: yup.string(),
  website: yup.string(),
  yearsRecruiting: yup.string().required("Years recruiting is required"),
  roleInPreviousOrganization: yup.string().required(),
  memberShip: yup.string(),
  zipCode: yup.string(),
  licenseNumber: yup.string(),
  contingentWorker: yup.string().required(),
  tempToDirectHire: yup.string().required(),
});

export const REGISTER_USER_INITIAL_STATE: RegisterUser = {
  agency_name: "",
  agreementDate: "",
  ATS: "",
  Certification: "",
  city: "",
  companies: "",
  companyOrPersonalDescription: "",
  companyType: "",
  country: "",
  DBACompanyName: "",
  educationLevel: "",
  email: "",
  employerId: "",
  ext: "",
  fax: "",
  faxExt: "",
  firmSize: "",
  firstName: "",
  hearAboutUs: "",
  industries: [],
  lastName: "",
  legalCompanyName: "",
  legalFirstName: "",
  legalLastName: "",
  licenseExpirationDate: "",
  licenseNumber: "",
  onlineResources: "",
  password: "",
  placementPercentage1: 0,
  placementPercentage2: 0,
  prefix: "",
  primaryPhoneNumber: "",
  recruitingLicenseNumber: "",
  schoolsAttended: "",
  secondaryExt: "",
  memberShip: "",
  secondaryPhoneNumber: "",
  sourcing: "",
  state: "",
  streetAddress: "",
  vat: "",
  website: "",
  yearsRecruiting: "",
  zipCode: "",
  roleInPreviousOrganization: "",
  contingentWorker: "0",
  tempToDirectHire: "0",
};

export const BULLET_LIST_HEADING = [
  {
    heading: "Reduce your overhead",
    text: "and let SorsX serve as your account managers.",
  },
  {
    heading: "Seamless integration with your business –",
    text: "change nothing and get everything!",
  },
  {
    heading: "Choose the best clients",
    text: "from our global and tech companies so you can start recruiting and earning.",
  },
  {
    heading: "Maximize your time and effort",
    text: "by working on verified openings with qualified employers.",
  },
];
