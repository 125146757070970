import { toast } from "react-toastify";
import axiosClient from "../utils/axios.ts";
import { RegisterUser } from "../types/user.ts";

class User {
  async createUser(values: RegisterUser) {
    try {
      const user = await axiosClient().post("users/signup/agency", {
        ...values,
        companies: Number(values.companies),
      });

      return user.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");

      console.log(error);

      return error;
    }
  }

  async loginUser(values) {
    try {
      const user = await axiosClient().post("users/login", values);

      return user.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");

      console.log("err=>", error);

      return error;
    }
  }

  async getUserByToken() {
    try {
      const user = await axiosClient().get("users/user-by-token");

      return user.data;
    } catch (error) {
      console.error("error", error);

      throw new Error(error);
    }
  }
}

export const usersApi = new User();
