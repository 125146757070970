import React from "react";
import { Col, Row, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Job } from "../../types/jobs";
import { ICONS_BY_CATEGORY } from "../../constants/icons-by-category.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
import { capitalizeFirstLetter } from "../../utils/captitalize-each-word.ts";
const Card = ({
  data,
  setIsOpen,
  setJobData,
}: {
  data: Job;
  setIsOpen: (newVal: boolean) => void;
  setJobData: any;
}): JSX.Element => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const date = new Date(data?.createdAt);
  const { user } = useAuthContext();
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const alreadyApplied = data.jobRequests.filter(
    (x) => x.agency.id === Number(user?.agencyId)
  );
  return (
    <Row className="border-b py-8 cursor-pointer z-10">
      <Col span={2}>
        <div>
          <div
            style={{
              background:
                "linear-gradient(180deg, #E8F5FF -0.48%, #B4DFFF 99.92%)",
            }}
            className="w-[77px] h-[77px] rounded-[20px] flex items-center justify-center"
          >
            {ICONS_BY_CATEGORY.map((iconItem) => {
              if (iconItem.category === data?.industry) return iconItem.icon;
              return null;
            })}
          </div>
        </div>
      </Col>
      <Col span={16} onClick={() => navigate(`/details/${data?.id}`)}>
        <div className="flex items-center gap-5">
          <Text className="text-[#5A59A1] font-semibold">{data?.status}</Text>
          <Text className="text-[#5A59A1] font-semibold">{formattedDate}</Text>
        </div>
        <Text className="font-semibold text-[#344054] text-[24px] capitalize">
          {data?.title &&
            data?.title.charAt(0).toUpperCase() + data?.title.slice(1)}
        </Text>
        <div className="flex items-center pt-3 gap-2">
          <img src="./assets/images/location.svg" alt="" />
          <Text className="text-[#475467] text-[18px] font-normal capitalize">
            {data?.address &&
              data?.address.charAt(0).toUpperCase() + data?.address.slice(1)}
          </Text>
        </div>
        <div className="pt-3 grid">
          {data?.showCompanyName && (
            <Text className="text-[#475467] text-[18px] font-semibold">
              Company Name:{" "}
              <span className="font-normal">{data?.company_name}</span>
            </Text>
          )}
          <Text className="text-[#475467] text-[18px] font-semibold">
            Fee: <span className="font-normal">{data?.feeAmount}</span>
          </Text>
          <Text className="text-[#475467] text-[18px] pt-2 font-semibold">
            Industry & Category:{" "}
            <span className="font-normal">
              {data?.industry} & {capitalizeFirstLetter(data?.category || "")}
            </span>
          </Text>
        </div>
        <div className="flex">
          <div className="grid border-r-2 w-[115px] mt-4 border-[#EFEBFF]">
            <Text className="text-[#5A59A1] text-[18px] pt-2 font-semibold">
              Recruiters
            </Text>
            <Text className="text-[#091534] text-[18px] pt-2 font-semibold">
              {
                data?.jobRequests?.filter(
                  (x) => x?.status.toLowerCase() === "approved"
                )?.length
              }
            </Text>
          </div>
          <div className="grid w-full mt-4 pl-3">
            <Text className="text-[#5A59A1] text-[18px] pt-2 font-semibold">
              Candidates Submitted
            </Text>
            <Text className="text-[#091534] text-[18px] pt-2 font-semibold">
              {data?.candidates?.length || 0}
            </Text>
          </div>
        </div>
      </Col>
      <Col span={6} className="flex items-end h-full">
        <Button
          onClick={() => {
            setIsOpen(true);
            setJobData(data);
          }}
          type="primary"
          htmlType="submit"
          className="w-full z-50 font-sans h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
          disabled={
            data?.status?.toLocaleLowerCase() === "not accepting submissions" ||
            alreadyApplied?.length > 0
          }
        >
          {data?.status?.toLocaleLowerCase() === "not accepting submissions"
            ? "Not Accepting Submissions"
            : alreadyApplied?.length > 0
            ? "Under review"
            : "Work Job"}
        </Button>
      </Col>
    </Row>
  );
};
export default Card;
