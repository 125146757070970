import React, { useState, useEffect } from "react";
import { Typography, Button, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { ICONS_BY_CATEGORY } from "../../constants/icons-by-category.tsx";
import EditModal from "./modal.tsx";
import useOpenJobs from "../../hooks/use-open-jobs.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
const Details = () => {
  const { Text } = Typography;
  const { jobId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const jobRequests = useSelector((state: RootState) => state.yourJobs.jobs);
  const [jobData, setJobData] = useState(jobRequests?.[0]);
  const { getAllOpenJobs } = useOpenJobs();
  const requiredJob = useSelector(
    (state: RootState) =>
      state.openJobs.jobs.filter((x) => String(x?.id) === jobId)[0]
  );
  useEffect(() => {
    if (!requiredJob) getAllOpenJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return setJobData(requiredJob as any);
  }, [requiredJob]);
  const { user } = useAuthContext();
  const alreadyApplied = requiredJob?.jobRequests?.filter(
    (x) => x.agency.id === Number(user?.agencyId)
  );
  return (
    <>
      {!requiredJob ? (
        <div className="flex py-32 justify-center w-full ">
          <Spin />
        </div>
      ) : (
        <div className="container my-10 ">
          <div className="flex items-center gap-4">
            <div
              style={{
                background:
                  "linear-gradient(180deg, #E8F5FF -0.48%, #BABADA 99.92%)",
              }}
              className="w-[77px] h-[77px] rounded-[20px] flex items-center justify-center"
            >
              {ICONS_BY_CATEGORY.map((iconItem) => {
                if (iconItem.category === requiredJob?.industry)
                  return iconItem.icon;
                return null;
              })}
            </div>
            <Text className="text-[#101828] text-[30px] font-semibold capitalize">
              {requiredJob?.title}{" "}
              <span className="text-[18px]">({requiredJob?.jobType})</span> -{" "}
              {requiredJob?.company_name}
            </Text>
          </div>
          <div className="w-full flex justify-end mt-10   gap-4">
            <Button
              onClick={() => setIsOpen(true)}
              htmlType="submit"
              disabled={
                requiredJob?.status?.toLocaleLowerCase() ===
                  "not accepting submissions" || alreadyApplied?.length > 0
              }
              className="w-[350px] font-sans h-[44px] bg-[#5A59A1] text-white rounded-[8px] font-semibold text-[16px] mt-4"
            >
              {requiredJob?.status?.toLocaleLowerCase() ===
              "not accepting submissions"
                ? "Not Accepting Submissions"
                : alreadyApplied?.length > 0
                ? "Under review"
                : "Work Job"}
            </Button>
          </div>
          <div className="mt-14 grid">
            {requiredJob?.showCompanyName && (
              <Text className="text-[#000000] text-[18px] mt-2 font-semibold">
                Company Name:
                <span className="font-normal pl-2">
                  {requiredJob?.company_name}
                </span>
              </Text>
            )}
            <Text className="text-[#000000] text-[18px] mt-2 font-semibold">
              Location:
              <span className="font-normal pl-2">{requiredJob?.location}</span>
            </Text>
            <Text className="text-[#000000] text-[18px] mt-2 font-semibold">
              Salary Range:
              <span className="font-normal pl-2">
                {requiredJob?.salaryRange}
              </span>
            </Text>
            <Text className="text-[#000000] text-[24px] mt-4 font-bold">
              Job description
            </Text>
            <div
              dangerouslySetInnerHTML={{
                __html: requiredJob?.publicJobDetails || "",
              }}
              style={{ margin: "8px 0px" }}
            />
          </div>
        </div>
      )}
      <EditModal isOpen={isOpen} setIsOpen={setIsOpen} data={jobData as any} />
      <div className="pt-10">
        <div className="w-full bg-[#5A59A1] text-center h-[45px] mt-40 items-center fixed bottom-0 flex justify-center">
          <Text className="text-[#FFFFFF] text-[18px] font-semibold">
            © 2024
          </Text>
        </div>
      </div>
    </>
  );
};
export default Details;
