import { Candidate } from "./../types/candidate.ts";
import { createSlice } from "@reduxjs/toolkit";

export interface CounterState {
  jobs: Candidate[];
}

const initialState: CounterState = {
  jobs: [],
};

export const counterSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    addPeople: (state, action) => {
      state.jobs = action.payload;
    },
  },
});

export const { addPeople } = counterSlice.actions;

export default counterSlice.reducer;
