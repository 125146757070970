import { toast } from "react-toastify";
import axiosClient from "../utils/axios.ts";

class Job {
  async getAllOpenJobs() {
    try {
      const jobs = await axiosClient().get("jobs/open");

      return jobs.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }

  async createJobRequest({ note, jobId }) {
    try {
      const jobs = await axiosClient().post("job-requests", { note, jobId });

      return jobs.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");

      console.log("error =>", error);
    }
  }

  async getJobRequests() {
    try {
      const jobs = await axiosClient().get("job-requests");

      return jobs.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const jobsApi = new Job();
