import React from "react";

const Anchor = ({
  data,
  activeTab,
  setActiveTab,
  isSign,
}: any): JSX.Element => {
  const showTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="flex gap-5 mt-8 border-b border-[#EAECF0]">
      {data?.map((item: any, index: number) => (
        <button
          key={index}
          style={
            isSign
              ? {
                  fontSize: "12px",
                }
              : {}
          }
          className={`tab ${
            activeTab === index
              ? "tab-active border-b-2 border-[#5A59A1] pb-2 text-[#5A59A1] text-[16px] font-semibold"
              : "tab-active border-b-2 border-transparent  pb-2 text-[#667085] text-[16px] font-semibold"
          }`}
          onClick={() => {
            showTab(index);
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default Anchor;
