import React from "react";
import { Typography, Button, Input } from "antd";
import { FormikProps } from "formik";
import { RegisterUser } from "../../../types/user";
import { toast } from "react-toastify";
const Prefference = ({
  setActiveTab,
  formik,
}: {
  setActiveTab: (newVal: number) => void;
  formik: FormikProps<RegisterUser>;
}): JSX.Element => {
  const { Text } = Typography;
  return (
    <div>
      <div className="mb-2">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[14px]"
        >
          Company/Personal Description*
        </Text>
        <Input
          name="companyOrPersonalDescription"
          onChange={formik.handleChange}
          value={formik.values.companyOrPersonalDescription}
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
        />
      </div>
      <div className="mb-2 mt-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[14px]"
        >
          Years Recruiting*
        </Text>
        <Input
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          name="yearsRecruiting"
          onChange={formik.handleChange}
          value={formik.values.yearsRecruiting}
        />
      </div>
      <div className="mb-2 mt-4">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[14px]"
        >
          Membership
        </Text>
        <Input
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          name="memberShip"
          onChange={formik.handleChange}
          value={formik.values.memberShip}
        />
      </div>
      <div className="mt-4 -mb-4">
        <Text style={{ color: "#344054" }}>Education Level</Text>
        <Input
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          name="educationLevel"
          onChange={formik.handleChange}
          value={formik.values.educationLevel}
        />
      </div>
      <div className="mb-2 mt-6">
        <Text
          style={{ color: "#344054" }}
          className="font-sans font-nomral text-[14px]"
        >
          Schools Attended
        </Text>
        <Input
          type=""
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          name="schoolsAttended"
          onChange={formik.handleChange}
          value={formik.values.schoolsAttended}
        />
      </div>
      <div className="mt-4 -mb-4">
        <Text style={{ color: "#344054" }}>Certification</Text>
        <Input
          className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
          name="Certification"
          onChange={formik.handleChange}
          value={formik.values.Certification}
        />
      </div>{" "}
      <Button
        type="primary"
        onClick={() => {
          if (
            formik.values.companyOrPersonalDescription?.length > 0 &&
            formik.values.yearsRecruiting?.length > 0
          ) {
            setActiveTab(4);
          } else toast.error("Please complete all required fields.");
        }}
        className="w-full h-[44px] my-8 bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
      >
        Continue
      </Button>
    </div>
  );
};
export default Prefference;
