import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { addOpenJobs } from "../../redux/open-jobs.ts";
import { addYourJobs } from "../../redux/your-jobs.ts";
import { UserOutlined } from "@ant-design/icons";
import { addPeople } from "../../redux/peoples.ts";
import Item from "./item.tsx";

const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (item: any, index: number) => {
    navigate(item.url);
  };

  const { signOut, user } = useAuthContext();

  const items = [
    {
      key: "1",
      label: (
        <div className=" border-b border-[#EAECF0] font-sans gap-3 p-3 flex w-full ">
          <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer flex justify-center items-center">
            <UserOutlined />
          </div>

          <div className="grid">
            <span className="text-[#344054] text-[14px] font-semibold capitalize">
              {user && user?.firstName} {user && user?.lastName}
            </span>

            <span className="-mt-1">{user && user?.email}</span>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => {
            dispatch(addOpenJobs([]));
            dispatch(addYourJobs([]));
            dispatch(addPeople([]));
            navigate("/");
            signOut();
          }}
          className="p-3 py-4 w-full"
        >
          <div className="flex items-center justify-start  w-full rounded-md  text-white  font-sans gap-3">
            <img
              src="https://ik.imagekit.io/8extk8sjo/log-out-01.svg?updatedAt=1710827754232"
              alt=""
            />

            <span className="text-[#344054] font-medium text-sm">Log out</span>
          </div>
        </div>
      ),
    },
  ];

  const jobsCount = useSelector(
    (state: RootState) => state?.openJobs?.jobs?.length
  );

  const YourJobsCount = useSelector(
    (state: RootState) => state?.yourJobs?.jobs?.length
  );

  const peopleCounts = useSelector(
    (state: RootState) => state.addPeople.jobs?.length
  );

  const NAV_ITEMS = [
    {
      label: "Home",
      isCount: false,
      counts: 0,
      url: "/home",
    },
    {
      label: "Open Jobs",
      isCount: true,
      counts: jobsCount,
      url: "/open-jobs",
    },
    {
      label: "Your jobs",
      isCount: true,
      counts: YourJobsCount,
      url: "/your-jobs",
    },
    {
      label: "Candidates",
      isCount: true,
      counts: peopleCounts,
      url: "/candidates",
    },
  ];
  return (
    <Item NAV_ITEMS={NAV_ITEMS} handleNavigate={handleNavigate} items={items} />
  );
};

export default Header;
