import { toast } from "react-toastify";
import axiosClient from "../utils/axios.ts";

class Candidate {
  async createCandidate(value: any) {
    try {
      const jobs = await axiosClient().post("candidate", value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return jobs.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went wrong");
    }
  }

  async getAllCandidates() {
    try {
      const jobs = await axiosClient().get("candidate");

      return jobs.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const candidatesApi = new Candidate();
