import React, { useEffect, useId, useState } from "react";
import { Typography } from "antd";
import Form from "./contact-section.tsx";
import { FormikProps, useFormik } from "formik";

import {
  BULLET_LIST_HEADING,
  REGISTER_USER_INITIAL_STATE,
  REGISTER_USER_SCHEMA,
} from "../../../constants/register.ts";

import { RegisterUser } from "../../../types/user.ts";
import { usersApi } from "../../../services/user.ts";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/logo.tsx";

const Main = () => {
  const { Text } = Typography;
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik: FormikProps<RegisterUser> = useFormik({
    initialValues: REGISTER_USER_INITIAL_STATE,
    validationSchema: REGISTER_USER_SCHEMA,
    onSubmit: async (values, formikHelpers) => {
      setIsLoading(true);

      const register = await usersApi.createUser({
        ...values,
        agency_name: values.legalCompanyName,
        agreementDate: new Date().toDateString(),
      });

      if (register?.success) {
        toast.success("User created successfully");

        setIsLoading(false);

        navigate("/");
      }
    },
  });

  const id = useId();

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      employerId: id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Logo />
      <div className="employer-container">
        <div className="content">
          <h1 style={{ fontSize: 24, marginBottom: -4 }}>
            Reach More Employers Actively Seeking Candidates
          </h1>
          <p style={{ fontSize: 16, margin: "16px 0" }}>
            Join our global network of recruiters and take advantage of our AI
            resources to build your business. We can open doors to new
            opportunities and introduce you to global companies looking for your
            expertise.
          </p>
          <p style={{ fontSize: 16, margin: "16px 0" }}>
            There is no fee to sign up and you get so many benefits as part of
            the SorsX team:
          </p>
          <ul>
            {BULLET_LIST_HEADING.map((x, i) => (
              <div key={i}>
                <li>
                  <img src="/assets/purple-tick.svg" alt="Checkmark" />
                  <p>
                    <strong>{x?.heading}</strong> {x?.text}
                  </p>
                </li>
                <hr
                  style={{
                    maxWidth: "200px",
                    marginTop: "-8px",
                    marginBottom: "8px",
                    marginLeft: "32px",
                  }}
                />
              </div>
            ))}
          </ul>
        </div>
        <div className="form-container max-h-[480px] overflow-y-scroll">
          <Form
            formik={formik}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="flex justify-center gap-32 items-end px-16 pt-8">
        <Text className="text-[#475467]">Copyright © 2024 WadiTek LLC</Text>
      </div>
    </>
  );
};

export default Main;
