import React, { useState, useCallback } from "react";
import { Modal, Typography, Button, Row, Col, Input, Spin } from "antd";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import {
  CANDIDATE_INITIAL_STATE,
  CANDIDATE_SCHEMA,
} from "../../constants/candidate.ts";
import { JobRequest } from "../../types/jobs.ts";
import { candidatesApi } from "../../services/candidate.ts";
import { toast } from "react-toastify";
import useYourJobs from "../../hooks/use-your-jobs.tsx";
const EditModal = ({
  isOpen,
  setIsOpen,
  data,
}: {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  data: JobRequest | undefined;
  getJobs?: any;
  setJobData?: any;
}) => {
  const { TextArea } = Input;
  const { Text, Title } = Typography;
  const [pdf, setPdf] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { getJobs: setJobsInRedux } = useYourJobs();
  const formik = useFormik({
    initialValues: CANDIDATE_INITIAL_STATE,
    validationSchema: CANDIDATE_SCHEMA,
    onSubmit: async (values, { resetForm }) => {
      if (!pdf) {
        toast.error("Kindly Upload Resume");
        return;
      }
      setIsLoading(true);
      const payload = {
        ...values,
        jobId: data?.job?.id,
        jobRequestId: data?.id,
        agencyId: data?.agency?.id,
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      formData.append("file", pdf);
      const submit = await candidatesApi.createCandidate(formData);
      if (submit) {
        await setJobsInRedux();
        toast.success("Candidate Submitted Successfully");
        setIsOpen(false);
        resetForm();
        setPdf(undefined);
      }
      setIsLoading(false);
    },
  });
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (
      file?.name?.includes(".pdf") ||
      file?.name?.includes(".doc") ||
      file?.name?.includes(".docx") ||
      file?.name?.includes(".jpg") ||
      file?.name?.includes(".jpeg")
    ) {
      setPdf(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else toast.error("Only PDF, DOC, DOCX, JPG and JPEG are required");
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Modal
      centered
      visible={isOpen}
      width={950}
      style={{ boxShadow: "0px 32px 64px -12px #10182824" }}
      footer={null}
      closable={false}
      wrapClassName="custom-modal"
    >
      <div className="flex justify-between items-center py-4 px-6 border-b ">
        <Text className="text-[#101828] text-[18px] font-semibold">
          Submit Candidate
        </Text>
        <img
          src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
          alt=""
          className="cursor-pointer"
          onClick={() => setIsOpen(false)}
          style={{ maxWidth: "24px" }}
        />
      </div>
      <div className=" max-h-[65vh] w-full overflow-y-scroll overflow-hidden">
        <Row className="p-4 px-6" gutter={20}>
          <Col span={8}>
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                First name*
              </Title>
              <Input
                type="text"
                placeholder="Enter your name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <p className="text-red-600">{formik.errors.firstName}</p>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Last name*
              </Title>
              <Input
                type="text"
                placeholder="Enter your last name"
                value={formik.values.lastName}
                name="lastName"
                onChange={formik.handleChange}
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <p className="text-red-600">{formik.errors.lastName}</p>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Title*
              </Title>
              <Input
                type="text"
                placeholder="Enter title"
                value={formik.values.title}
                name="title"
                onChange={formik.handleChange}
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
              />
              {formik.errors.title && formik.touched.title && (
                <p className="text-red-600">{formik.errors.title}</p>
              )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Phone No
              </Title>
              <Input
                type="text"
                placeholder="Enter Phone No"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
              />
              {formik.errors.phone && formik.touched.phone && (
                <p className="text-red-600">{formik.errors.phone}</p>
              )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Email address
              </Title>
              <Input
                type="text"
                placeholder="Enter Email address"
                name="email"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email && (
                <p className="text-red-600">{formik.errors.email}</p>
              )}
            </div>
          </Col>
          <Col span={8} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Country
              </Title>
              <Input
                type="text"
                placeholder="Country"
                name="country"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.country}
                onChange={formik.handleChange}
              />
              {formik.errors.country && formik.touched.country && (
                <p className="text-red-600">{formik.errors.country}</p>
              )}
            </div>
          </Col>
          <Col span={8} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                City
              </Title>
              <Input
                type="text"
                placeholder="City"
                name="city"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
              {formik.errors.city && formik.touched.city && (
                <p className="text-red-600">{formik.errors.city}</p>
              )}
            </div>
          </Col>
          <Col span={8} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Citizenship
              </Title>
              <Input
                type="text"
                placeholder="Citizenship"
                name="citizenship"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.citizenship}
                onChange={formik.handleChange}
              />
              {formik.errors.citizenship && formik.touched.citizenship && (
                <p className="text-red-600">{formik.errors.citizenship}</p>
              )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Current Work Status:
              </Title>
              <Input
                type="text"
                placeholder="Enter title"
                name="currentWorkStatus"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.currentWorkStatus}
                onChange={formik.handleChange}
              />
              {formik.errors.currentWorkStatus &&
                formik.touched.currentWorkStatus && (
                  <p className="text-red-600">
                    {formik.errors.currentWorkStatus}
                  </p>
                )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Requested Compensation:
              </Title>
              <Input
                type="text"
                placeholder="Enter Requested Compensation:"
                name="requestedCompensation"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.requestedCompensation}
                onChange={formik.handleChange}
              />
              {formik.errors.requestedCompensation &&
                formik.touched.requestedCompensation && (
                  <p className="text-red-600">
                    {formik.errors.requestedCompensation}
                  </p>
                )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Open to relocation
              </Title>
              <Input
                type="text"
                placeholder=""
                name="openToReLocation"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.openToReLocation}
                onChange={formik.handleChange}
              />
              {formik.errors.openToReLocation &&
                formik.touched.openToReLocation && (
                  <p className="text-red-600">
                    {formik.errors.openToReLocation}
                  </p>
                )}
            </div>
          </Col>
          <Col span={12} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Interview Availability
              </Title>
              <Input
                type="text"
                placeholder=""
                name="interviewAvailability"
                className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.interviewAvailability}
                onChange={formik.handleChange}
              />
              {formik.errors.interviewAvailability &&
                formik.touched.interviewAvailability && (
                  <p className="text-red-600">
                    {formik.errors.interviewAvailability}
                  </p>
                )}
            </div>
          </Col>
          <Col span={24} className="mt-6">
            <div>
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Candidate Summary, Comments, Certifications, and Qualifications
              </Title>
              <TextArea
                // type="text"
                autoSize
                placeholder=""
                name="summary"
                className="w-full py-8 h-[94px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                value={formik.values.summary}
                onChange={formik.handleChange}
              />
              {formik.errors.summary && formik.touched.summary && (
                <p className="text-red-600">{formik.errors.summary}</p>
              )}
            </div>
          </Col>
          {!pdf && (
            <Col span={24} className="my-6 ">
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                Attach Resume
              </Title>
              <div className="border border-[#EAECF0] cursor-pointer w-full rounded-[12px] flex justify-center items-center p-5">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <div className="text-center flex items-center justify-center">
                      <div>
                        <div className="flex justify-center w-full">
                          <img
                            src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(4).svg?updatedAt=1709799572323"
                            alt=""
                            className="h-[45px] w-[45px]"
                          />
                        </div>
                        <p className="text-[#475467] text-[12px]">
                          <span className="text-[#5A59A1] text-[14px] font-semibold">
                            Click to upload
                          </span>{" "}
                          or drag and drop <br />
                          PDF , DOC, DOCX, JPG, JPEG allowed
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          )}
          {pdf && (
            <Col span={24} className="my-6 flex gap-8">
              <Title
                level={5}
                style={{ color: "#344054" }}
                className="font-sans font-medium text-[14px]"
              >
                {pdf?.name}
              </Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
                onClick={() => setPdf(undefined)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </Col>
          )}
        </Row>
      </div>
      <div className="px-4 py-8 flex gap-3 border-t items-center justify-between">
        <Button
          onClick={() => setIsOpen(false)}
          size="large"
          className="w-full h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="w-full h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
          onClick={() => formik.handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <Spin /> : "Submit"}
        </Button>
      </div>
    </Modal>
  );
};
export default EditModal;
