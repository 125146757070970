import React, { useState } from "react";
import { Typography, Table, Row, Col, Button } from "antd";
import JobDetailModal from "./job-detail-modal.tsx";
import { Job } from "../../types/jobs";
const TableSection = ({
  setIsOpen,
  data,
  title,
  job,
}: {
  setIsOpen: (newVal: boolean) => void;
  data: any[];
  title: string;
  job?: Job;
}): JSX.Element => {
  const { Text } = Typography;
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const columns = [
    {
      dataIndex: "firstName",
      className: "font-medium text-[#101828] capitalize text-sm",
    },
    {
      dataIndex: "status",
      className: "",
      render: (text: string) => {
        const statusColor =
          text && text.toLowerCase() === "hired"
            ? "bg-[#d8f0e2] text-center py-1 rounded-[15px] w-[100px]"
            : text && text.toLowerCase() === "rejected"
            ? "bg-red-200 text-center py-1 rounded-[15px] w-[100px]"
            : "bg-transparent text-center py-1 rounded-[15px] w-[100px]";
        return <div className={statusColor}>{text}</div>;
      },
    },
    {
      dataIndex: "createdAt",
      className: "w-[250px] text-sm text-[#475467]",
      render: (text: string) => (text ? new Date(text).toDateString() : ""),
    },
    {
      dataIndex: "resumeURL",
      className: "w-[150px] text-sm text-[#475467]",
      render: (text: string) =>
        text ? (
          <Button onClick={() => window.open(text)}>Download Resume</Button>
        ) : null,
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {};
  const HiredCounts = data?.filter((x) => x?.status === "Hired");
  const PendingCounts = data?.filter((x) => x?.status === "Pending");
  const RejectedCounts = data?.filter((x) => x?.status === "Rejected");
  return (
    <div>
      <div className="flex items-start w-full justify-between">
        <div>
          <Text className="text-[#5A59A1] text-[30px] capitalize font-semibold">
            {title}
          </Text>
          <div className="bg-[#d8f0e2] rounded-[16px] px-4 py-1 mt-1 w-[160px] text-center text-[#027A48] text-[12px] font-semibold">
            Accepting Submissions
          </div>
        </div>
        <Button
          onClick={() => setIsOpen(true)}
          type="primary"
          htmlType="submit"
          className="w-[328px] font-sans h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
        >
          Submit
        </Button>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={() => setIsDetailModalOpen(true)}
          type="primary"
          htmlType="submit"
          className="w-[328px] font-sans h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
        >
          View Job Details
        </Button>
      </div>
      <div className="mt-14">
        <Text className="flex items-center gap-2 my-4 text-[#475467] pl-4 text-[12px]">
          Submissions
        </Text>
        <div className="border rounded-[8px] overflow-hidden border-[#EAECF0]">
          <Table
            columns={columns}
            dataSource={data}
            onChange={onChange}
            showHeader={false}
            pagination={false}
            rowKey={(record) => record.id} // Assuming you have a unique identifier like 'id'
          />
        </div>
        <Text className="flex items-center gap-2 mt-6 mb-4 text-[#475467] pl-4 text-[12px]">
          Submissions
        </Text>
        <Row className="w-full border p-10 bg-white rounded-[8px]">
          <Col
            span={8}
            className="flex justify-center items-center border-r-2 border-[#EFEBFF]"
          >
            <div className="grid">
              <Text className="text-[#0C2E82] text-[30px] font-medium text-center">
                {PendingCounts?.length || 0}
              </Text>
              <Text className="text-[#555ED8] text-[18px] mt-6 font-medium">
                Pending Submissions
              </Text>
            </div>
          </Col>
          <Col
            span={8}
            className="flex justify-center items-center border-r-2 border-[#EFEBFF]"
          >
            <div className="grid">
              <Text className="text-[#0C2E82] text-[30px] font-medium text-center">
                {RejectedCounts?.length || 0}
              </Text>
              <Text className="text-[#555ED8] text-[18px] mt-6 font-medium">
                Rejected Submissions
              </Text>
            </div>
          </Col>
          <Col span={8}>
            <div className="grid">
              <Text className="text-[#0C2E82] text-[30px] font-medium text-center ">
                {HiredCounts?.length || 0}
              </Text>
              <Text className="text-[#555ED8] text-[18px] mt-6 font-medium text-center">
                Hired Submissions
              </Text>
            </div>
          </Col>
        </Row>
      </div>
      <JobDetailModal
        data={job}
        isOpen={isDetailModalOpen}
        setIsOpen={setIsDetailModalOpen}
      />
    </div>
  );
};
export default TableSection;
