import * as yup from "yup";
import { CreateCandidateDto } from "../types/candidate.ts";

export const CANDIDATE_SCHEMA = yup.object().shape({
  citizenship: yup.string().required("Citizenship is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  currentWorkStatus: yup.string().required("Current work status is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  firstName: yup.string().required("First name is required"),
  interviewAvailability: yup
    .string()
    .required("Interview availability is required"),
  lastName: yup.string().required("Last name is required"),
  openToReLocation: yup.string().required("Open to relocation is required"),
  phone: yup.string().required("Phone number is required"),
  requestedCompensation: yup
    .string()
    .required("Requested compensation is required"),
  summary: yup.string().required("Summary is required"),
  title: yup.string().required("Title is required"),
});

export const CANDIDATE_INITIAL_STATE: CreateCandidateDto = {
  agencyId: "",
  citizenship: "",
  city: "",
  country: "",
  currentWorkStatus: "",
  email: "",
  firstName: "",
  interviewAvailability: "",
  jobId: "",
  jobRequestId: "",
  lastName: "",
  openToReLocation: "",
  phone: "",
  requestedCompensation: "",
  summary: "",
  title: "",
};
