import React from "react";
import {
  TbBusinessplan,
  TbBuildingWarehouse,
  TbBuildingCommunity,
} from "react-icons/tb";
import { MdPeopleAlt } from "react-icons/md";
import { VscLaw } from "react-icons/vsc";
import { SiTaichigraphics } from "react-icons/si";
import { GiTeamIdea } from "react-icons/gi";
import { LuFactory } from "react-icons/lu";
import { FiWifi } from "react-icons/fi";
import { FaAward } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { BsPeopleFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import {
  BiBriefcase,
  BiCloud,
  BiDetail,
  BiHeartCircle,
  BiSolidWrench,
  BiSupport,
} from "react-icons/bi";
export const ICONS_BY_CATEGORY = [
  {
    category: "Sales and Marketing",
    icon: (
      <TbBusinessplan
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Human Resources",
    icon: (
      <MdPeopleAlt
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Legal",
    icon: (
      <VscLaw style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Graphic Design",
    icon: (
      <SiTaichigraphics
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "R&D",
    icon: (
      <GiTeamIdea style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Manufacturing",
    icon: (
      <LuFactory style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "CyberSecurity",
    icon: (
      <FiWifi style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Quality Assurance and Control",
    icon: (
      <FaAward style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Supply Chain and Logistic",
    icon: (
      <TbBuildingWarehouse
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Public Relations",
    icon: (
      <BsPeopleFill
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Consulting",
    icon: (
      <IoIosPeople
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Media and Communication",
    icon: (
      <TbBuildingCommunity
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Information Technology",
    icon: (
      <BiCloud style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Engineering",
    icon: (
      <BiSolidWrench
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Finance and Accounting",
    icon: (
      <FaMoneyBillTransfer
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Healthcare",
    icon: (
      <BiHeartCircle
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Administration",
    icon: (
      <BiDetail style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
  {
    category: "Management",
    icon: (
      <BiBriefcase
        style={{ height: "40px", width: "40px", color: "#585bb8" }}
      />
    ),
  },
  {
    category: "Customer Service",
    icon: (
      <BiSupport style={{ height: "40px", width: "40px", color: "#585bb8" }} />
    ),
  },
];
