import React from "react";
import { Typography } from "antd";
import { JobRequest } from "../../types/jobs.ts";
import { ICONS_BY_CATEGORY } from "../../constants/icons-by-category.tsx";
import { capitalizeFirstLetter } from "../../utils/captitalize-each-word.ts";

const Card = ({
  data,
  activeCard,
  index,
  setActiveCard,
  setJobData,
}: {
  data: JobRequest;
  activeCard: any;
  index: any;
  setActiveCard: any;
  setJobData: any;
}): JSX.Element => {
  const { Text } = Typography;
  const date = new Date(data?.job?.createdAt);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  return (
    <>
      <div
        onClick={() => {
          setJobData(data);
          setActiveCard(index);
        }}
        className={`border-b py-5 flex gap-4 cursor-pointer px-6 ${
          activeCard === index ? "bg-[#EFF4FF]" : "bg-white"
        }`}
      >
        <div
          style={{
            background:
              "linear-gradient(180deg, #E8F5FF -0.48%, #B4DFFF 99.92%)",
          }}
          className="w-[42px] h-[42px] rounded-[10px] mt-3 flex items-center justify-center"
        >
          {ICONS_BY_CATEGORY.map((iconItem) => {
            if (iconItem.category === data?.job?.industry) {
              return iconItem.icon;
            }
            return null;
          })}
        </div>

        <div>
          <div className="flex items-center gap-3">
            <Text className="text-[#0C2E82] font-semibold text-[14px]">
              {formattedDate}
            </Text>
          </div>

          <Text className="font-semibold text-[#344054] capitalize text-[24px]">
            {data?.job?.title}
          </Text>

          <div className="flex items-center pt-1 gap-2">
            <img
              src="./assets/images/location.svg"
              className="w-[15px]"
              alt=""
            />
            <Text className="text-[#475467] text-[14px] font-normal">
              {data?.job?.address}
            </Text>
          </div>

          <div className="pt-1 grid">
            <Text className="text-[#475467] text-[14px] font-semibold">
              Fee: <span className="font-normal">{data?.job?.feeAmount}</span>
            </Text>

            <Text className="text-[#475467] text-[14px] pt-1 font-semibold">
              Industry & Category:{" "}
              <span className="font-normal">
                {data?.job?.industry} &{" "}
                {capitalizeFirstLetter(data?.job?.category || "")}
              </span>
            </Text>
          </div>

          <div className="flex">
            <div className="grid border-r-2 w-[120px]  border-[#EFEBFF]">
              <Text className="text-[#5A59A1] text-[13px] pt-2 font-semibold">
                Recruiters
              </Text>
              <Text className="text-[#091534] text-[13px]  font-semibold">
                {
                  data?.job?.jobRequests?.filter(
                    (x) => x?.status?.toLowerCase() === "approved"
                  )?.length
                }
              </Text>
            </div>
            <div className="grid w-full  pl-3">
              <Text className="text-[#5A59A1] text-[13px] pt-2 font-semibold whitespace-nowrap">
                Candidates Submitted
              </Text>
              <Text className="text-[#091534] text-[13px]  font-semibold">
                {data?.job?.candidates?.length || 0}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
