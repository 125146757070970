import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Spin } from "antd";
import Card from "./card.tsx";
import TableSection from "./table-section.tsx";
import EditModal from "./modal.tsx";
import useYourJobs from "../../hooks/use-your-jobs.tsx";
import Loader from "../../components/loader.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { useAuthContext } from "../../context/auth-context.tsx";

const Main = (): JSX.Element => {
  const { Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);
  const [activeCard, setActiveCard] = useState<any>(0);
  const jobRequests = useSelector((state: RootState) => state.yourJobs.jobs);
  const [jobData, setJobData] = useState(jobRequests?.[0]);
  const { user } = useAuthContext();

  useEffect(() => {
    setJobData(jobRequests?.[activeCard]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRequests, jobData]);

  const { isLoading, getJobs } = useYourJobs();

  useEffect(() => {
    getJobs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-20">
      <div className="container mt-10">
        {isLoading && jobRequests?.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="grid mb-8">
              <Text className="text-[#101828] text-[30px] font-semibold font-sans">
                Welcome,{" "}
                {user ? (
                  user?.firstName &&
                  user.firstName.charAt(0).toUpperCase() +
                    user.firstName.slice(1)
                ) : (
                  <Spin />
                )}
              </Text>
            </div>

            {jobRequests?.length > 0 ? (
              <Row gutter={20}>
                <Col span={7}>
                  <div
                    style={{
                      boxShadow:
                        "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
                    }}
                    className="w-full border-[#EAECF0] bg-[#FFFFFF] border  rounded-[12px] 
                  "
                  >
                    {jobRequests?.length > 0 ? (
                      <>
                        {jobRequests &&
                          jobRequests?.length > 0 &&
                          jobRequests?.map((item: any, index: any) => (
                            <div>
                              <Card
                                key={index}
                                data={item}
                                activeCard={activeCard}
                                index={index}
                                setActiveCard={setActiveCard}
                                setJobData={setJobData}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      <div className="w-full justify-center items-center text-center font-semibold py-10">
                        No data
                      </div>
                    )}
                  </div>
                </Col>

                <Col span={17}>
                  <TableSection
                    data={jobData ? jobData?.job?.candidates : []}
                    title={jobData ? jobData?.job?.title : ""}
                    setIsOpen={setIsOpen}
                    job={jobData?.job}
                  />
                </Col>
              </Row>
            ) : (
              <Col span={24}>
                <div className="w-full my-40 justify-center flex items-center">
                  <img src="./assets/images/no_job.svg" alt="" />
                </div>
              </Col>
            )}
          </>
        )}
      </div>
      <EditModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={jobData ? jobData : undefined}
        getJobs={getJobs}
        setJobData={setJobData}
      />
    </div>
  );
};

export default Main;
