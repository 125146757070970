import React from "react";
import { Col, Row, Typography } from "antd";
import Form from "./form.tsx";
import Logo from "../../../components/logo.tsx";

const Main = (): JSX.Element => {
  const { Text } = Typography;

  return (
    <>
      <Logo />

      <Row>
        <Col xxl={12} lg={12} span={24} className="mx-auto">
          <div className="h-full grid items-center w-full  py-4">
            <div className="">
              <Form />
            </div>
          </div>
        </Col>
      </Row>

      <div className="pt-32">
        <hr />

        <div className="flex justify-center items-end px-16 pt-4">
          <Text className="text-[#475467] font-sans">
            Copyright © 2024 WadiTek LLC
          </Text>
        </div>
      </div>
    </>
  );
};

export default Main;
