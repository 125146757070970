import React from "react";
import Header from "../../layout/header/index.tsx";
import Main from "../../views/notification/main.tsx";
import { Typography } from "antd";

const Settings = (): JSX.Element => {
  const { Text } = Typography;

  return (
    <>
      <Header />

      <Main />

      <div className="flex justify-center my-4">
        <Text className="text-[#475467] font-sans">
          Copyright © 2024 WadiTek LLC
        </Text>
      </div>
    </>
  );
};

export default Settings;
