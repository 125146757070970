import React from "react";
import { Col, Row, Typography, Spin } from "antd";
import Card from "./card.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
import { RootState } from "../../redux/store.ts";
import { useSelector } from "react-redux";

const Main = () => {
  const { Text } = Typography;
  const { user } = useAuthContext();

  const jobsCount = useSelector(
    (state: RootState) => state?.openJobs?.jobs?.length
  );

  const YourJobsCount = useSelector(
    (state: RootState) => state?.yourJobs?.jobs?.length
  );

  const peopleCounts = useSelector(
    (state: RootState) => state.addPeople.jobs?.length
  );

  const TOTAL_CARDS = [
    {
      label: "Open jobs",
      counts: jobsCount,
    },
    {
      label: "Your jobs",
      counts: YourJobsCount,
    },
    {
      label: "Candidates",
      counts: peopleCounts,
    },
  ];

  return (
    <>
      <div className="container pt-10 ">
        <div className="grid">
          <Text className="text-[#101828] text-[30px] font-semibold font-sans">
            Welcome,{" "}
            <span className="capitalize">
              {user ? (
                user?.firstName &&
                user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
              ) : (
                <Spin />
              )}
            </span>
          </Text>
        </div>

        <Row gutter={30}>
          {TOTAL_CARDS.map((item: any, i) => (
            <Col
              key={i}
              xxl={8}
              xl={8}
              lg={8}
              md={12}
              sm={24}
              xs={24}
              className="mt-8"
            >
              <Card label={item?.label} count={item?.counts} />
            </Col>
          ))}
        </Row>

        <Row>
          <Col span={24} className="mt-8 overflow-hidden bg-white">
            <div
              style={{
                boxShadow:
                  "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
              }}
              className="p-4 w-full rounded-[12px]  grid border border-[#EAECF0] overflow-hidden"
            >
              <div className="flex justify-between items-center">
                <Text className="#475467 text-[18px] font-semibold">
                  Our Global Network
                </Text>
              </div>

              <div className="w-full mt-7">
                <img
                  src="./assets/images/grapgh.png"
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Main;
