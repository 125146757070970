import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../context/auth-context.tsx";
import { useNavigate } from "react-router-dom";

export const GuestGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, user } = useAuthContext();
  const ignore = useRef(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ignore.current = true;

    if (isAuthenticated) {
      navigate("/home");
    } else {
      setChecked(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  if (!checked) {
    return null;
  }

  return children;
};
